import ShoppingCart from '@c/ShoppingCart';
import ErrorToast from '@c/toasts/ErrorToast';
import ItemAddedToast from '@c/toasts/ItemAdded';
import { AddressDocument } from '@models/address';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getItem, getItems, logEvent } from '@util/analytics';
import {
  calcCart,
  calculateShipping,
  getCartItemsSubtotal,
  getCartState,
  getDefaultCart,
  getShippingRegion,
  logCart,
  setCartState,
} from '@util/firestore/cart';
import { getProductById } from '@util/firestore/products';
import { Rate } from '@util/firestore/shipengine';
import useRealTimeProducts from '@util/hooks/useRealtimeProducts';
import { getCartItemFromProductDocument, keysDiff } from '@util/index';
import { logError } from '@util/logError';
import { Cart, CartItem } from '@util/types/firestore/carts';
import { ProductDocument, Variation } from 'models/product';
import React, { createContext, ReactNode, useEffect, useState } from 'react';
import { useAttributionContext } from './AttributionContext';
import { useAuth } from './AuthContext';
import { useToastContext } from './ToastContext';
import { usePathname } from 'next/navigation';
import { Coupon } from 'models/coupon';

type ShoppingCartProviderProps = {
  children: ReactNode;
};
type ShoppingCartContext = {
  addCoupons: (coupons: Coupon[]) => void;
  calculatingTax: boolean;
  canAddProductToCart: (productId: string) => boolean;
  cart?: Cart | null; // cart is null when user is not logged in, undefined when loading in, and Cart when loaded
  cartOpen: boolean;
  clearCart: () => void;
  clearCartAndRedirect: (url: string) => Promise<void>;
  decreaseCartQty: (id: string) => void;
  getCartItemsSubtotal: (items: CartItem[]) => number;
  getItemCount: () => number;
  getItemQty: (id: string) => number;
  handleShippingRateSelected: (
    sellerId: string,
    productId: string,
    rate: Rate
  ) => void;
  increaseCartQty: (item: CartItem, dontOpenCart?: boolean) => void;
  realTimeProducts: ProductDocument[];
  removeRateIds: () => void;
  removeCoupon: (coupon: Coupon) => void;
  removeFromCart: (id: string) => void;
  setCalculatingTax: React.Dispatch<React.SetStateAction<boolean>>;
  setCartOpen: React.Dispatch<React.SetStateAction<boolean>>;
  updateCartItem: (item: CartItem) => void;
  updateDonationAmount: (amount: number) => void;
  updateShippingAddress: (address: AddressDocument | null) => void;
  updateTax: (items: CartItem[], shipping_address?: AddressDocument) => void;
  updateRAFee: (ra_fee: number) => void;
  setPaymentMethodId: (paymentMethodId: 'affirm' | 'balance' | string) => void;
};

const ShoppingCartContext = createContext({} as ShoppingCartContext);

export function useShoppingCart() {
  const shoppingCart = React.useContext(ShoppingCartContext);
  if (!shoppingCart) {
    throw new Error('CurrentUserContext: No value provided');
  }
  return shoppingCart;
}

export function ShoppingCartProvider({ children }: ShoppingCartProviderProps) {
  const queryClient = useQueryClient();
  const { userDoc } = useAuth();

  const pathname = usePathname();
  // Firestore Queries / Mutations.
  const { data: cart } = useQuery({
    queryKey: ['cart', userDoc?.uid],
    queryFn: () =>
      getCartState({
        uid: userDoc?.uid ?? '',
      }),
  });
  const setCartMutation = useMutation({
    mutationFn: setCartState,
    onMutate: async ({ newCartState }) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: ['cart'] });

      // Snapshot the previous value
      const previousCart = queryClient.getQueryData(['cart', userDoc?.uid]) as
        | Cart
        | undefined;

      // Optimistically update to the new value
      queryClient.setQueryData(['cart'], (old: Cart | undefined) => ({
        ...(old && { old }),
        ...newCartState,
      }));

      // Return a context object with the snapshotted value
      return { previousCart };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newCart, context) => {
      showErrorToast((err as Error).message);
      if (context) {
        logError(err);
        queryClient.setQueryData(['cart'], context.previousCart);
      }
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['cart'] });
    },
  });
  // --------------------------------------------

  // Live object of products in cart
  const { realTimeProducts } = useRealTimeProducts(cart?.product_ids ?? []);

  // CRUD operations on cart item quantities
  async function increaseCartQty(newItem: CartItem, dontOpenCart = false) {
    if (!userDoc || !cart) return;
    const productDoc = await getProductById(newItem.product_id);

    if (
      productDoc?.out_of_stock ||
      // (productDoc?.stock ?? 0) <= 0 || // allowing negative stock
      !productDoc?.date_added
    ) {
      notifyError(0);
      return;
    }

    const currItems = cart?.items ?? []; // initialize cart items if empty
    const index = currItems.findIndex(
      (currItem) => currItem.product_id === newItem.product_id
    );

    // if the item does not exist in the cart
    if (index === -1) {
      // Calculate new cart and update in firestore
      const newCart = calcCart({
        currCart: cart,
        created: cart?.created ?? Date.now(),
        items: [...currItems, newItem],
        uid: userDoc.uid,
        products: realTimeProducts,
      });
      setCartMutation.mutate({ newCartState: newCart });
      // notifyItemsAdded();
      // ------
      // Analytics
      logCart(newItem, productDoc, userDoc);
      // ------
    } else {
      // if the item already exists in the cart
      const cartItem = currItems[index];
      const newQty = cartItem.qty + newItem.qty;
      const newItemProductDoc = realTimeProducts.find(
        (p) => p.id === newItem.product_id
      );
      if (newItemProductDoc && newQty > newItemProductDoc.stock) {
        // if user tries to add more items than are in stock
        notifyError(newItemProductDoc.stock); // notify user
        return;
      }
      cartItem.qty = newQty; // update cart item quantity

      // Analytics
      logCart(newItem, productDoc, userDoc);
      // ------

      // Calculate new cart and update in firestore
      const newCart = calcCart({
        currCart: cart,
        created: cart?.created ?? Date.now(),
        items: currItems,
        uid: userDoc.uid,
        products: realTimeProducts,
      });
      setCartMutation.mutate({ newCartState: newCart });
      // ------
    }

    if (cart && !cartOpen && !dontOpenCart) {
      logEvent('view_cart', {
        items: getItems(realTimeProducts),
        currency: 'USD',
        value: getCartItemsSubtotal(cart.items),
      });

      if (!pathname?.includes('/my-cart')) setCartOpen(true);
    }
  }
  function decreaseCartQty(id: string) {
    if (!cart) return;
    const currItems = cart.items;
    const index = currItems.findIndex((item) => item.product_id === id);
    if (index === -1) return;
    const item = currItems[index];
    if (item.qty === 1) {
      const filteredItems = currItems.filter((item) => item.product_id !== id);
      const product = realTimeProducts.find((p) => p.id === id);
      if (product) {
        logEvent('remove_from_cart', {
          currency: 'USD',
          items: [getItem(product)],
        });
      }
      const newCart = calcCart({
        currCart: cart,
        created: cart.created,
        items: filteredItems,
        uid: cart.uid,
        products: realTimeProducts,
      });
      setCartMutation.mutate({ newCartState: newCart });
    } else {
      item.qty -= 1;
      const newCart = calcCart({
        currCart: cart,
        created: cart.created,
        items: currItems,
        uid: cart.uid,
        products: realTimeProducts,
      });
      setCartMutation.mutate({ newCartState: newCart });
    }
  }
  function removeFromCart(id: string) {
    if (!userDoc || !cart) return;
    const currItems = cart?.items ?? []; // initialize cart items if empty
    const filteredItems = currItems.filter((item) => item.product_id !== id);
    const newCart = calcCart({
      currCart: cart,
      created: cart?.created ?? Date.now(),
      items: filteredItems,
      uid: userDoc.uid,
      products: realTimeProducts,
    });
    setCartMutation.mutate({ newCartState: newCart });
  }
  // --------------------------------------------

  const { showToast, showErrorToast, showSuccessToast } = useToastContext();

  // Local State
  const [cartOpen, setCartOpen] = useState(false);
  const [calculatingTax, setCalculatingTax] = useState(false);
  // const [calculatingReturnInfo, setCalculatingReturnInfo] = useState(false);
  // --------------------------------------------

  // updates cart when realtime products change
  const { getAttribution } = useAttributionContext();
  useEffect(() => {
    if (!cart) return;
    let shouldUpdate = false;
    const notifications: ('' | 'removed' | 'updated')[] = [];
    const products = [...realTimeProducts];
    // 1. generate cart items based on most update to date product info
    let currCartItems = products.map((p) => {
      const currCartItem = cart.items.find((i) => i.product_id === p.id);
      const currVariations = p.variations?.filter(
        (v) =>
          (v.size === currCartItem?.size?.letter ||
            v.size === currCartItem?.size?.number) &&
          (!v.color || v.color === currCartItem?.color)
      );
      // split up variations into size and color if applicable
      const sv = {
        ...currVariations?.find((v) => v.size === currCartItem?.size?.letter),
      };
      if (Object.keys(sv).length) {
        delete sv.color;
      }
      const nv = {
        ...currVariations?.find((v) => v.size === currCartItem?.size?.number),
      };
      if (Object.keys(nv).length) {
        delete nv.color;
      }
      const cv = {
        ...currVariations?.find((v) => v.color === currCartItem?.color),
      };
      if (Object.keys(cv).length) {
        cv.size = '';
      }
      const variations = [sv, nv, cv].filter(
        (v) => !!Object.keys(v).length
      ) as Variation[];
      const attribution = getAttribution(p.id);
      const newCartItem = getCartItemFromProductDocument(
        p,
        variations?.length ? variations : null,
        currCartItem?.attribution ?? attribution
      );
      newCartItem.qty = currCartItem?.qty ?? 1;
      return {
        ...currCartItem,
        ...newCartItem,
      };
    });

    // 2. check if any realtime products are out of stock
    const outOfStock = products.filter(
      (p) => p.out_of_stock && !p.bought_by?.includes(cart.uid)
    );
    if (outOfStock.length) {
      notifications.push('removed');
      shouldUpdate = true;
      // remove out of stock items from cart
      currCartItems = currCartItems.filter((i) => {
        const found = outOfStock.find((p) => p.id === i.product_id);
        return !found;
      });
    }

    // 3. check if any cart items have qty greater than stock
    const itemsWithQtyGreaterThanStock = currCartItems.filter((i) => {
      const product = products.find((p) => p.id === i.product_id);
      return i.qty > (product?.stock ?? 0);
    });
    if (itemsWithQtyGreaterThanStock.length) {
      notifications.push('updated');
      shouldUpdate = true;
      // set cart item qty to stock
      currCartItems = currCartItems.map((i) => {
        const product = products.find((p) => p.id === i.product_id);
        if (i.qty > (product?.stock ?? 0)) {
          i.qty = product?.stock ?? 0;
        }
        return i;
      });
      currCartItems = currCartItems.filter((i) => i.qty > 0);
    }

    // 4. check if any cart items with size variations have zero qty
    const itemsWithSizes = currCartItems.filter((i) => i.size !== undefined);
    const variationsToRemove = itemsWithSizes
      .map((i) => {
        const product = products.find((p) => p.id === i.product_id);
        const variations = product?.variations?.filter(
          (v) =>
            (!v.size ||
              v.size === i.size?.letter ||
              v.size === i.size?.number) &&
            (!v.color || v.color === i.color)
        );
        if (!variations?.length) return;
        const sizes: (string | number)[] = [];
        variations.forEach((v) => {
          if (v.qty <= 0) {
            sizes.push(v.size);
          }
        });
        return sizes;
      })
      .flat()
      .filter(Boolean) as (string | number)[];
    if (variationsToRemove.length) {
      shouldUpdate = true;
      notifications.push('removed');
      // remove items with zero qty from cart (even for gear combos. Maybe we only remove one of the sizes , but that adds complexity)
      currCartItems = currCartItems.filter((i) => {
        const size = i.size?.letter || i.size?.number;
        if (size) {
          return !variationsToRemove.includes(size);
        }
        return true;
      });
    }

    // 5. find cart items with shipping costs already selected
    const cartItemsWithShipping = cart.items.filter((i) => i.shipping_region);
    // check if any cart items with shipping costs need to update their shipping costs based on the latest product info
    const shippingUpdates = cartItemsWithShipping
      .map((i) => {
        const found = products.find((p) => p.id === i.product_id);
        if (!found) {
          return;
        }
        if (!found.is_flat_rate) return;
        const shippingRate = calculateShipping(
          found,
          i.shipping_region ?? 'US'
        );
        if (!shippingRate) {
          //  remove shipping cost if it is no longer available
          return {
            shipping_cost: undefined,
            shipping_region: undefined,
            item_id: i.product_id,
          };
        }
        if (i.shipping_cost !== shippingRate.cost) {
          return {
            shipping_cost: shippingRate.cost,
            shipping_region: shippingRate.code,
            item_id: i.product_id,
          };
        }
      })
      .filter(Boolean) as {
      shipping_cost: number | undefined;
      shipping_region: string | undefined;
      item_id: string;
    }[];
    if (shippingUpdates.length) {
      notifications.push('updated');
      shouldUpdate = true;
    }
    // for each shipping update, update the cart item
    shippingUpdates.forEach((update) => {
      currCartItems = currCartItems.map((i) => {
        if (!update.shipping_cost) {
          // remove shipping cost if it is no longer available
          delete i.shipping_cost;
          delete i.shipping_region;
          return i;
        }
        if (i.product_id === update.item_id) {
          return {
            ...i,
            shipping_cost: update.shipping_cost,
            shipping_region: update.shipping_region,
          };
        }
        return i;
      });
    });

    // 6. check if other keys have changed, this could include cost, title, slug, etc.
    const diff = keysDiff(currCartItems, cart.items, '', true);
    if (diff.length) {
      notifications.push('updated');
      shouldUpdate = true;
    }

    if (shouldUpdate) {
      const newCart = calcCart({
        currCart: cart,
        products: realTimeProducts,
        items: currCartItems,
        created: cart.created,
        uid: cart.uid,
      });
      setCartMutation.mutate({
        newCartState: newCart,
      });
      notifications.forEach((n) => {
        if (n === 'removed')
          showErrorToast('An item in your cart has been removed');
        if (n === 'updated')
          showSuccessToast('An item in your cart has been updated');
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realTimeProducts]);

  // #region cart interactions
  function getItemCount() {
    if (!cart) return 0;
    const total = cart.items.reduce(
      (accumulator, cartItem) => accumulator + cartItem.qty,
      0
    );
    return total;
  }

  function getItemQty(id: string) {
    if (!cart) return 0;
    return cart.items.find((item) => item.product_id === id)?.qty || 0;
  }

  function addCoupons(coupons: Coupon[]) {
    const currCart = cart;
    if (!currCart) return;
    const newCart = calcCart({
      currCart,
      uid: currCart.uid,
      items: currCart.items,
      coupons,
      products: realTimeProducts,
    });
    setCartMutation.mutate({ newCartState: newCart });
  }

  function removeCoupon(coupon: Coupon) {
    const currCart = cart;
    if (!currCart) return;
    const newCart = calcCart({
      currCart,
      uid: currCart.uid,
      items: currCart.items,
      coupons: currCart.coupons?.filter((c) => c.code !== coupon.code) ?? [],
      products: realTimeProducts,
    });
    setCartMutation.mutate({ newCartState: newCart });
  }

  function handleShippingRateSelected(
    sellerId: string,
    productId: string,
    rate: Rate
  ) {
    const currCart = cart;
    if (!currCart || !currCart.shipping_address) return;
    const index = currCart.items.findIndex(
      (i) => i.product_id === productId && i.seller_id === sellerId
    );
    if (index === -1) return;

    currCart.items[index].shipping_cost = rate.total_amount;
    currCart.items[index].shipping_region = getShippingRegion(
      currCart.shipping_address
    );
    currCart.items[index].rate_id = rate.rate_id;
    currCart.items[index].service_type = rate.service_type;
    const newCart = calcCart({
      currCart,
      items: currCart.items,
      created: currCart.created,
      uid: currCart.uid,
      shipping_address: currCart.shipping_address,
      products: realTimeProducts,
    });
    setCartMutation.mutate({ newCartState: newCart });
  }

  function updateShippingAddress(address: AddressDocument | null) {
    if (!cart) return;
    if (address === null) delete cart.shipping_address;
    const newCart = calcCart({
      currCart: cart,
      items: cart.items,
      uid: cart.uid,
      ...(address && { shipping_address: address }),
      products: realTimeProducts,
    });
    setCartMutation.mutate({ newCartState: newCart });
  }

  function updateTax(items: CartItem[], shipping_address?: AddressDocument) {
    if (!cart) return;
    const newCart = calcCart({
      currCart: cart,
      uid: cart.uid,
      items,
      products: realTimeProducts,
      coupons: cart.coupons,
      donation_amount: cart.donation_amount,
      ...(shipping_address && { shipping_address }),
    });
    setCartMutation.mutate({ newCartState: newCart });
  }

  function updateDonationAmount(amount: number) {
    if (!cart) return;
    const newCart = calcCart({
      currCart: cart,
      uid: cart.uid,
      items: cart.items,
      donation_amount: amount,
      products: realTimeProducts,
    });
    setCartMutation.mutate({ newCartState: newCart });
  }
  function clearCart() {
    if (!userDoc) return;
    setCartMutation.mutate({ newCartState: getDefaultCart(userDoc.uid) });
  }

  async function clearCartAndRedirect(url: string) {
    if (!userDoc) return;
    await setCartState({ newCartState: getDefaultCart(userDoc.uid) });
    window.location.href = url;
  }

  function canAddProductToCart(productId: string) {
    if (!cart) return false;
    const product = realTimeProducts.find((p) => p.id === productId);
    if (!product) return true; // if product is not found, assume it can be added
    const cartItem = cart.items.find((i) => i.product_id === productId);
    if (!cartItem) return true;
    return cartItem.qty < product.stock;
  }

  function updateCartItem(item: CartItem) {
    const currCart = cart;
    if (!currCart) return;
    const index = currCart.items.findIndex(
      (i) => i.product_id === item.product_id
    );
    if (index === -1) return;
    currCart.items[index] = item;
    const newCart = calcCart({
      currCart,
      uid: currCart.uid,
      items: currCart.items,
      products: realTimeProducts,
    });
    setCartMutation.mutate({ newCartState: newCart });
  }

  function removeRateIds() {
    if (!cart) return;
    cart.items.forEach((item) => {
      if (item.rate_id) {
        delete item.rate_id;
        delete item.service_type;
        delete item.shipping_cost;
        delete item.shipping_region;
      }
    });
    const newCart = calcCart({
      currCart: cart,
      uid: cart.uid,
      items: cart.items,
      products: realTimeProducts,
    });
    setCartMutation.mutate({ newCartState: newCart });
  }

  function setPaymentMethodId(paymentMethodId: 'affirm' | 'balance' | string) {
    if (!cart) return;
    cart.payment_method_id = paymentMethodId;
    if (paymentMethodId === 'affirm') {
      cart.payment_type = 'affirm';
    } else if (paymentMethodId === 'balance') {
      cart.payment_type = 'balance';
    } else {
      cart.payment_type = 'card';
    }
    const newCart = calcCart({
      currCart: cart,
      uid: cart.uid,
      items: cart.items,
      products: realTimeProducts,
      coupons: cart.coupons,
      donation_amount: cart.donation_amount,
    });
    setCartMutation.mutate({ newCartState: newCart });
  }

  function updateRAFee(ra_fee: number) {
    if (!cart) return;
    cart.ra_fee = ra_fee;
    const newCart = calcCart({
      currCart: cart,
      uid: cart.uid,
      items: cart.items,
      products: realTimeProducts,
      coupons: cart.coupons,
      donation_amount: cart.donation_amount,
    });
    setCartMutation.mutate({ newCartState: newCart });
  }

  // #endregion cart interactions

  function notifyError(qty: number) {
    const message =
      qty === 0
        ? 'This item is out of stock.'
        : qty === 1
        ? 'There is only 1 available of this item.'
        : `There are only ${qty} available of this item.`;
    showToast(<ErrorToast message={message} />, {
      closeButton: false,
      style: {
        borderRadius: '16px',
        backgroundColor: '#FF5555',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: '#FF5555',
        width: '400px',
      },
    });
  }

  return (
    <ShoppingCartContext.Provider
      value={{
        addCoupons,
        canAddProductToCart,
        cart,
        cartOpen,
        clearCart,
        clearCartAndRedirect,
        decreaseCartQty,
        getItemCount,
        getItemQty,
        getCartItemsSubtotal,
        handleShippingRateSelected,
        increaseCartQty,
        realTimeProducts,
        removeRateIds,
        removeCoupon,
        removeFromCart,
        setCartOpen,
        calculatingTax,
        setCalculatingTax,
        updateShippingAddress,
        updateCartItem,
        updateTax,
        updateDonationAmount,
        updateRAFee,
        setPaymentMethodId,
      }}
    >
      <ShoppingCart />
      {children}
    </ShoppingCartContext.Provider>
  );
}
